@mixin button(
    $background,
    $border,
    $color,
    $hover-background,
    $hover-border,
    $hover-color,
    $active-background,
    $active-border,
    $active-color,
    $disabled-background,
    $disabled-border,
    $disabled-color
) {
    @include button-variant(
        $background,
        $border,
        $color,
        $hover-background,
        $hover-border,
        $hover-color,
        $active-background,
        $active-border,
        $active-color,
        $disabled-background,
        $disabled-border,
        $disabled-color
    );

    .icon {
        font-size: 20px;
    }

    .spinner-border {
        display: none;
    }

    &.btn-l {
        min-height: 48px;
    }

    &.btn-m {
        min-height: 40px;
    }

    &.btn-s {
        min-height: 32px;
    }

    &.loading {
        border-color: $active-border;
        background-color: $active-background;
        color: $active-color;
        pointer-events: none;

        .spinner-border {
            display: block;
        }

        :not(.spinner-border) {
            visibility: hidden;
        }
    }
}

.btn-primary.light {
    @include button($green-600, $green-600, $white, $green-700, $green-700, $white, $green-800, $green-800, $white, $gray-300, $gray-300, $white);
}

.btn-primary.dark {
    @include button($white, $white, $gray-950, $gray-50, $gray-50, $gray-950, $gray-100, $gray-100, $gray-950, $white, $white, $gray-300);
}

.btn-secondary.light {
    @include button(inherit, $gray-400, $gray-950, $gray-50, $gray-950, $gray-950, $gray-100, $gray-950, $gray-950, inherit, $gray-100, $gray-300);
}

.btn-secondary.dark {
    @include button(inherit, $white, $white, $white, $white, $gray-950, $gray-100, $white, $gray-950, inherit, rgba($white, 0.5), rgba($white, 0.5));
}

.btn-accent {
    @include button($pink-600, $pink-600, $white, $pink-700, $pink-700, $white, $pink-800, $pink-800, $white, $gray-300, $gray-300, $white);
}
