* {
    // fix para navegadores mac
    -webkit-font-smoothing: antialiased;
    outline: none;
}

*:focus {
    outline: 0;
}

body {
    color: $gray-33;
    font-family: $font-base;
    font-size: $font-normal;
    font-weight: normal;
    line-height: 24px;
}

h1 {
    padding: 0;
    margin: 0;
    color: $gray-33;
    font-family: $font-base-medium;
    font-size: $font-heading-1;
    line-height: 44px;
}

h2 {
    padding: 0;
    margin: 0;
    color: $gray-33;
    font-family: $font-base-medium;
    font-size: $font-heading-2;
    line-height: 37px;
}

h3 {
    padding: 0;
    margin: 0;
    color: $gray-33;
    font-family: $font-base-medium;
    font-size: $font-heading-3;
    line-height: 32px;
}

h4 {
    padding: 0;
    margin: 0;
    color: $gray-33;
    font-family: $font-base-medium;
    font-size: $font-heading-4;
    line-height: 27px;
}

.h2-alter {
    font-family: $font-base-medium;
    font-size: $font-alter-heading-2;
    line-height: 56px;
}

a {
    color: $gray-33;
    font-family: $font-base;
    text-decoration: none;

    &:hover {
        color: $gray-33;
    }
}

.subtitle {
    font-family: $font-base-semi-bold;
    font-size: $font-medium;
    line-height: 28px;
}

.caption {
    color: #282828;
    font-size: $font-small;
}

.primary-color {
    color: $primary;
}

.club-color {
    color: $club !important;
}

.club-secondary-color {
    color: $club-secondary !important;
}

@mixin line-clamp($lines) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}

.scrollbar {
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: $gray-300;
    }

    &::-webkit-scrollbar {
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }
}

.vertical-scrollbar {
    @extend .scrollbar;

    &::-webkit-scrollbar {
        width: 8px;
    }
}

.tooltip-inner {
    text-align: left;
}

.inline-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .inline-icon-content {
        flex: 1;

        p {
            margin-bottom: 0;
            font-size: $font-small;

            &.title {
                font-family: $font-base-bold;
            }
        }
    }
}

.modal-backdrop.show {
    opacity: 0.7;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: center;
}

.disabled {
    opacity: 0.25;
    pointer-events: none;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

b,
strong {
    font-family: $font-base-semi-bold;
    font-weight: 500;
}

[x-cloak] {
    display: none !important;
}

.hide-empty {
    &:empty {
        display: none;
    }
}
